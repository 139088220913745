<template>
  <div class="card mb-3 shadow-sm sticky-md-top">
    <div class="card-body">
      <!-- Tab Navigation -->
      <ul
        class="nav nav-pills align-items-center"
        id="pills-tab"
        role="tablist"
      >
        <!-- General Information -->
        <li
          class="col-12 col-md-auto nav-item p-0 mb-2 mb-md-0 position-relative"
          role="presentation"
        >
          <a
            href="#general-info"
            class="nav-link active"
            id="general-info-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="general-info"
            aria-selected="true"
          >
            <i
              class="fas fa-exclamation-triangle text-warning shake-it"
              v-if="checkError('info')"
            ></i>
            <i class="far fa-list-alt" v-else></i>
            <span class="ml-1">Genel Bilgiler</span>
          </a>
        </li>
        <!-- ./General Information -->

        <!-- Product Details -->
        <li
          class="col-12 col-md-auto p-0 nav-item ml-0 ml-md-2 mb-2 mb-md-0"
          role="presentation"
        >
          <a
            href="#product-detail"
            class="nav-link"
            id="product-detail-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="product-detail"
            aria-selected="false"
          >
            <i class="fas fa-bars"></i>
            <span class="ml-1">Ürün Detayları</span>
          </a>
        </li>
        <!-- ./Product Details -->

        <!-- Product Variants -->
        <li
          class="col-12 col-md-auto p-0 nav-item ml-0 ml-md-2 mb-2 mb-md-0"
          role="presentation"
          v-if="checkVariant"
        >
          <a
            href="#product-variation"
            class="nav-link"
            id="variation-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="product-variation"
            aria-selected="false"
          >
            <i class="fas fa-code-branch"></i>
            <span class="ml-1">Varyasyonlar</span>
          </a>
        </li>
        <!-- ./Product Variants -->

        <!-- Marketplace -->
        <li
          class="col-12 col-md-auto p-0 nav-item ml-0 ml-md-2 mb-2 mb-md-0"
          role="presentation"
          v-if="marketplaceIntegration"
        >
          <a
            href="#product-marketplace"
            class="nav-link"
            id="variation-tab"
            data-toggle="pill"
            role="tab"
            aria-controls="product-marketplace"
            aria-selected="false"
          >
            <i
              class="fas fa-exclamation-triangle text-warning shake-it"
              v-if="checkError('marketplace')"
            ></i>
            <i class="fas fa-cart-plus" v-else></i>
            <span class="ml-1">Pazaryeri</span>
          </a>
        </li>
        <!-- ./Marketplace -->

        <!-- Submit Button -->
        <li class="nav-item ml-md-auto mx-auto mx-md-0">
          <div class="row align-items-center">
            <div class="col-auto p-0">
              <WeLanguageSwitcher v-model="product.lang" />
            </div>
            <div class="col pl-0">
              <WeSubmitButton
                parent-class="text-center"
                v-bind:submit-status="submit"
                v-bind:update="$route.params.id"
                v-bind:permission="checkPermission"
                v-on:submit="saveForm"
              />
            </div>
          </div>
        </li>
        <!-- ./Submit Button -->
      </ul>
      <!-- Tab Navigation -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "Navigation",
  props: {
    submit: {
      default: false,
    },
    errors: {
      default: () => [],
    },
  },
  methods: {
    saveForm() {
      this.$emit("save-form");
    },
    checkError(type) {
      return this.errors.includes(type);
    },
  },
  computed: {
    checkVariant() {
      let selectedProductType = this.productDetail.product_type;
      if (selectedProductType) {
        let checkIndex = this.productTypes.findIndex(
          (item) => item.id === selectedProductType
        );
        if (checkIndex < 0 || checkIndex == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    checkPermission() {
      return permission.check("product", "u");
    },
    ...mapState({
      config: (state) => state.session.config,
      product: (state) => state.product,
      productDetail: (state) => state.product.detail,
      productTypes: (state) => state.productType.types,
    }),
    marketplaceIntegration() {
      return (
        this.config["site.marketplace_integration"] == 1 ||
        this.config["site.marketplace_integration"] == "1"
      );
    },
  },
};
</script>
